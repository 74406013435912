import React from "react";
import {PlayerProps} from "../types/player-props.type";
import {v4 as uuid} from 'uuid';
import 'webrtc-adapter';
import {connect} from "react-redux";
import {PlayerStats} from '../../common/types/player-stats.type';
import "./index.scss";
import EventDropdown from "../events-dropdown/events-dropdown";
import SportEvent from "../../schedule/models/sport-event";
import {State} from "../../common/types/state.type";
import RemoteControl from "../../schedule/remote-control";
import {ISkyBoxState} from "../../common/types/sky-boxes";
import {ILogModel} from "../../common/types/statistics.type";
import {getBrowserType} from "../../utils/helpers";
import {startLogRecord, stopLogRecord} from "../../services/logs.service";

interface SLDPPlayerState {
    initial_resolution: string,
    playerStats: PlayerStats;
    skyBox: ISkyBoxState | null | undefined;
    remoteControlVisible: boolean;
    logId: number;
}

class SLDPPlayer extends React.Component<PlayerProps, SLDPPlayerState> {
    sldpPlayer: any;
    containerId: string;

    constructor(props) {
        super(props);
        this.containerId = uuid().toString();
        const {event, playerNumber} = props;

        this.resizeHandler = this.resizeHandler.bind(this);
        this.clickHandler = this.clickHandler.bind(this);

        window.addEventListener('resize', this.resizeHandler);

        this.state = {
            initial_resolution: '480p',
            playerStats: {
                id: this.containerId,
                type: 'wss',
                sport: event.sport,
                eventId: event.eventId,
                information: event.information,
                homeTeam: event.competitor1,
                awayTeam: event.competitor2,
                playerNumber: playerNumber,
                competitions: event.competition,
                eventDateTime: event.startTime,
                downloadedSnippets: [],
                startWatchingTime: new Date(),
            },
            skyBox: null,
            remoteControlVisible: false,
            logId: 0
        }
    }

    isFullScreen() {
        let fullscreenElement: Element | null = null;

        function f(x) {
            return fullscreenElement == null && typeof x !== 'undefined' && x != null;
        }

        if(f(document.fullscreenElement)) {
            fullscreenElement = document.fullscreenElement;
        }
        // @ts-ignore
        if(f(document.mozFullScreenElement)) {
            // @ts-ignore
            fullscreenElement = document.mozFullScreenElement;
        }
        // @ts-ignore
        if(f(document.webkitFullscreenElement)) {
            // @ts-ignore
            fullscreenElement = document.webkitFullscreenElement;
        }
        // @ts-ignore
        if(f(document.msFullscreenElement)) {
            // @ts-ignore
            fullscreenElement = document.msFullscreenElement;
        }

        if(fullscreenElement !== null) return true;

        // if( (window.screen.availHeight || window.screen.height - 50) <= window.innerHeight) {
        //     // almost fullscreen
        //     return true;
        // }
        // if( window.innerHeight == window.screen.height) {
        //     // fullscreen
        //     return true;
        // }
        return false;
    }

    resizeHandler(e) {
        if(this.isFullScreen()) {
            return;
        } else {
            this.props.isFullscreenToggle(false);
        }
        this.destroyPlayer();
        this.setupPlayer(false);
        document.body.style.overflow = 'hidden';
    }

    clickHandler(e: Event) {
        e.preventDefault();
        e.stopPropagation();
        return null;
    };

    setupPlayer(muted: boolean) {
        if(typeof (window['SLDP']) !== 'object') throw new Error('SLDP player not imported');
        const SLDP = window['SLDP'];
        let streamUrl: string = this.props.options.event.wssLink;
        let initialResolution = this.state.initial_resolution;
        let width = this.props.width;
        let height = this.props.height;
        let latencyToleranceMs = this.props.user!.restriction.latencyToleranceMs;
        var bufferingMs = this.props.user!.restriction.bufferingMs;

        if(!initialResolution) initialResolution = '480p';
        if(!width) width = 500;
        if(!height) height = 500;
        if(!latencyToleranceMs || latencyToleranceMs <= 0) latencyToleranceMs = 1250;
        if(!bufferingMs || bufferingMs <= 0) bufferingMs = 1250;

        const streamUrlParts = streamUrl.split('?');
        let qualitySuffix;
        if(this.props.isHd === true) {
            initialResolution = '720p';
            qualitySuffix = '_720';
        } else {
            qualitySuffix = '_576';
        }
        streamUrl = streamUrlParts[0] + qualitySuffix + '?' + streamUrlParts[1];

        const config = {
            container: this.containerId,
            stream_url: streamUrl,
            initial_resolution: initialResolution,
            height: height,
            width: width,
            controls: true,
            //settings from Sean
            autoplay: true,
            muted,
            offset: 2500,
            buffering: bufferingMs,
            latency_tolerance: latencyToleranceMs,
            key_frame_alignment: true

            //latency_adjust_method: 'fast-forward',
            //additional changes
            //audio_title :'my audio',
            //reconnects: 10,
            //adaptive_bitrate: !0,
            //muteable: !1,
            //fullscreen: !1,
            //muted: !1,
            //ios_failback_scheme: "sldp",
            //ios_failback_secure_scheme: "sldps",
            //ios_failback_app_url: "https://itunes.apple.com/us/app/sldp-player/id1238237026"
        };

        // //just for debug
        // window['config'] = config;



        try {
            this.sldpPlayer = SLDP.init(config);
            // @ts-ignore
            document.getElementsByClassName('sldp_config_btn')[0].remove()
            document.getElementsByClassName('sldp_play_pause_btn')[0].remove()
            document.querySelectorAll(`[id="${this.containerId}"] .sldp_player_wrp_video video`).forEach(element => {
                (element as any).style = 'height: 100%!important; width: 100%!important;';
            });
            // @ts-ignore
            document.querySelector(`[id="${this.containerId}"] video`).addEventListener('click', this.clickHandler, true);
            window['sldpPlayer'] = this.sldpPlayer;

        } catch (e) {
            console.error('SLDP.EXCEPTION', e);
        }
    }

    componentDidMount() {
        this.setupPlayer(true);

        const { event, playerNumber, isRealtime, isHd, sessionId } = this.props;
        if (event.eventId !== 0) {
            const log: ILogModel = {
                browserType: getBrowserType(),
                sessionId: sessionId,
                deviceUuid: localStorage.getItem('deviceUuid'),
                eventId: event.wtScheduledEventId,
                eventSport: event.sport,
                eventName: event.description || event.competition + (event.information && ' ' + event.information),
                eventContentProvider:event.service,
                playerNumber: playerNumber,
                streamType: isRealtime
                        ? isHd ? 'HD Live' : 'SD Live'
                        : isHd ? 'HD Review' : 'SD Review'
            }
            startLogRecord(log).then(response => this.setState({logId: response.data || response}));
        }
        this.setSkyBoxForPlayer();
    }

    componentWillUnmount() {
        const {logId} = this.state;

        if(!isNaN(logId))
            stopLogRecord(logId);

        this.destroyPlayer();
        window.removeEventListener('resize', this.resizeHandler);
        document.removeEventListener('click', this.clickHandler);
    }

    componentDidUpdate(prevProps: Readonly<PlayerProps>, prevState: Readonly<SLDPPlayerState>, snapshot?: any) {
        if(prevProps.streamSettings.length !== this.props.streamSettings.length) {
            this.destroyPlayer();
            this.setupPlayer(false);
        }

        if(prevProps.sessionId != this.props.sessionId){
            if(!isNaN(this.state.logId))
                stopLogRecord(this.state.logId);

            const { event, playerNumber, isRealtime, isHd, sessionId } = this.props;
            if (event.eventId !== 0) {
                const log: ILogModel = {
                    browserType: getBrowserType(),
                    sessionId: sessionId,
                    deviceUuid: localStorage.getItem('deviceUuid'),
                    eventId: event.wtScheduledEventId,
                    eventSport: event.sport,
                    eventName: event.description || event.competition + (event.information && ' ' + event.information),
                    eventContentProvider:event.service,
                    playerNumber: playerNumber,
                    streamType: isRealtime
                        ? isHd ? 'HD Live' : 'SD Live'
                        : isHd ? 'HD Review' : 'SD Review'
                }
                startLogRecord(log).then(response => this.setState({logId: response.data || response}));
            }
        }
    }

    destroyPlayer() {
        return this.sldpPlayer.destroy();
    }

    handleDropdownEventSelected = (link: string, isRealtime: boolean, isHd: boolean, event: SportEvent) => {

        const {playerNumber} = this.props;
        const {playerStats} = this.state;

        const newPlayerDetails = {
            id: uuid(),
            type: event.vodLink ? 'vod' : 'live/hls',
            sport: event.sport,
            latency: event.service,
            eventId: event.eventId,
            information: event.information,
            homeTeam: event.competitor1,
            playerNumber: playerNumber,
            awayTeam: event.competitor2,
            competitions: event.competition,
            eventDateTime: event.startTime,
            startWatchingTime: new Date(),
            downloadedSnippets: []
        }

        playerStats.endWatchingTime = new Date();

        this.setState({
            playerStats: newPlayerDetails
        })

        const stream: any = this.props.openEvents && this.props.openEvents.find((item: any) => item.eventId === event.eventId)
        const streamLink = stream && stream.streamLink;

        if(isRealtime) {
            this.props.onChangePlayer(stream.webRTCSource, playerNumber, isRealtime, isHd, stream);
            return;
        }
        this.props.onChangePlayer(streamLink, playerNumber, isRealtime, isHd, stream);
    }

    onStreamClose = () => {
        this.props.onChangePlayer('', this.props.playerNumber, true, false)
    }

    setSkyBoxForPlayer = () => {
        const link = this.props.options.event.wssLink;
        const skyBox = this.props.skyBoxes.find(item => item.wssStreamLink === link);
        this.setState({skyBox});
    }

    renderLiveIcon = () => {
        const {isHd} = this.props.options;
        return (
            <div className="live-icon">
                <div className="icon-container">
                    <span className='dot' style={{backgroundColor: 'red'}}></span>
                    <span className="live-label">{isHd ? 'HD Live' : 'SD Live'}</span>
                </div>
            </div>
        )
    }

    remoteControlToggle = () => {
        this.setState({remoteControlVisible: !this.state.remoteControlVisible});
    }

    render() {
        const {width, height, openEvents, multicastModalToggle, playerNumber, streamSettings} = this.props;

        return (
            <div className='sldp-container'>
                <div
                    id={this.containerId}
                >
                </div>
                {
                    !this.props.isPlaySkyBoxStream &&
                    <EventDropdown
                        width={width}
                        height={height}
                        events={openEvents || []}
                        currentEventId={this.state.playerStats.eventId}
                        onPlayerClose={() => this.props.onPlayerClose(this.props.playerNumber)}
                        onStreamClose={() => this.onStreamClose()}
                        onEventSelect={this.handleDropdownEventSelected}
                        playerNumber={playerNumber}
                        streamSettings={this.props.streamSettings}
                    />
                }
                {this.renderLiveIcon()}
                {
                    this.props.user
                    && this.props.user!.restriction.isSkyBoxesAllowed
                    && this.props.isPlaySkyBoxStream
                    && this.props.activeSkyBox && (this.props.activeSkyBox!.userEmail === this.props.user.email
                        || this.props.activeSkyBox!.lockType === 0)
                    &&
                    <div className="sldp-options-icons"
                         style={streamSettings.length === 2 ? {bottom: "240px"} : {bottom: "60px"}}
                    >
                        <div className="multicast-icon" onClick={multicastModalToggle}></div>
                        <div className="remote-icon" onClick={this.remoteControlToggle}></div>
                    </div>
                }
                {
                    this.state.remoteControlVisible && this.props.isPlaySkyBoxStream &&
                    <RemoteControl
                        remoteControlToggle={this.remoteControlToggle.bind(this)}
                        activeBox={this.props.activeSkyBox}
                        playerNumber={playerNumber}
                        streamSettings={streamSettings}
                        skyBox={this.state.skyBox}
                    />
                }
            </div>
        );
    }
}


export const mapStateToProps = (state: State, ownProps: any) => {
    const {user, skyBoxes, logs, sessionId} = state;
    return {
        user,
        skyBoxes: skyBoxes.source,
        logs,
        sessionId: sessionId
    };
}

export default connect(mapStateToProps)(SLDPPlayer);

